<template>
  <div :class="contentClass">
    <div class="d-flex justify-space-between">
      <div v-if="showTitle" class="filter-title">Filtros</div>
      <div class="d-flex align-center">
        <div class="filter-title-spacing">
          <div v-if="!showSwitch" class="d-flex">
            <AGSwitch
              v-for="item in switches"
              :id="item.id"
              :key="item.id"
              v-model="item.value"
              :name="item.id + '_name'"
              :count="countCedido"
              :label="item.label"
              :disabled="item.disabled"
              @input="toggleSwitch(item)"
            />
          </div>
        </div>
        <div :id="idToAttach" class="filter-spacing">
          <v-menu
            ref="filter_menu"
            v-model="menuActive"
            :close-on-content-click="false"
            :close-on-click="true"
            :persistent="true"
            z-index="4"
            offset-y
            left
            bottom
            :attach="'#' + idToAttach"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                id="btnFilter"
                class="btn-ag-secondary"
                v-bind="attrs"
                :disabled="disabled"
                @click="onClickFilter"
                v-on="on"
              >
                <v-icon class="material-icons-outlined"> filter_alt </v-icon>
                Filtro {{ filterSelectedCounter }}
              </v-btn>
            </template>
            <v-card class="filter-dropdown">
              <div class="d-flex flex-row justify-space-between filter-dropdown-header">
                <strong class="filter-title"> Filtro </strong>
                <div>
                  <v-icon class="primary--text" @click="closeFilterDialog"> close </v-icon>
                </div>
              </div>

              <div class="filters-dropdown-content">
                <slot name="filters"> </slot>
              </div>

              <div class="filters-buttons-container">
                <div class="btn-apply-filter">
                  <v-btn class="btn-ag-primary" :disabled="disabledFilterApplyBtn" @click="applyFilter()">
                    Aplicar
                  </v-btn>
                </div>
                <div class="btn-clear-filter">
                  <v-btn class="btn-ag-secondary" :disabled="!hasFilterSelected" @click="clearFilters()">
                    <v-icon> mdi-delete-outline </v-icon>
                    Limpar filtro
                  </v-btn>
                </div>
              </div>
            </v-card>
          </v-menu>
        </div>
      </div>
    </div>
    <div class="d-flex flex-row-reverse">
      <v-chip
        v-for="(chip, index) in filterChips"
        :key="index"
        close
        close-icon="close"
        class="filter-chip"
        @click:close="closeChip(chip)"
      >
        <span v-if="chip.quantity > 1" class="mr-1"> ({{ chip.quantity }}) </span>{{ chip.label }}
      </v-chip>
    </div>
  </div>
</template>
<script>
export default {
  name: 'FilterEncapsulated',
  components: {
    AGSwitch: () => import('./AGSwitch.vue'),
  },

  props: {
    showTitle: {
      type: Boolean,
      required: false,
      default: true,
    },

    switches: {
      type: Array,
      required: false,
    },

    showSwitch: {
      type: Boolean,
      required: false,
    },

    countCedido: {
      type: Number,
      required: false,
    },

    disabled: {
      type: Boolean,
      default: false,
    },

    disabledFilterApplyBtn: {
      type: Boolean,
      default: false,
    },

    heightDialog: {
      type: String,
      required: false,
      default: '650px',
    },

    contentClass: {
      type: String,
      required: false,
    },
  },

  data() {
    return {
      switchesData: [],
      menuActive: false,
      filterCounter: 0,
      filterChips: [],
    };
  },

  computed: {
    hasFilterSelected() {
      return this.filterCounter > 0;
    },

    filterSelectedCounter() {
      return this.hasFilterSelected ? `(${this.filterCounter})` : '';
    },

    heightForDialog() {
      return { '--height-dialog': this.heightDialog };
    },

    idToAttach() {
      return _.uniqueId('open_filter_btn_');
    },
  },

  methods: {
    toggleSwitch(item) {
      this.$emit('toggleSwitch', item);
    },

    onClickFilter() {
      this.menuActive = !this.menuActive;
    },

    onChangeDialog() {
      if (!this.menuActive) {
        this.$emit('closeFilterDialog');
      }
    },

    closeFilterDialog() {
      this.menuActive = false;
      this.$emit('closeFilterDialog');
    },

    applyFilter() {
      this.filterCounter = 0;
      this.$slots.filters.forEach((item) => {
        //console.log('item.', item.data.model);
        if (![undefined, null].includes(item.data)) {
          if ([undefined, null].includes(item.data.model)) {
            console.error('Filtro não tem model para validar quantidade de filtros selecionados');
          } else if (item.data.model.value != null && item.data.model.value.length > 0) {
            this.filterCounter++;
            // this.addChip(item);
          } else if (item.data.model.value === true) {
            this.filterCounter++;
          } else if ([undefined, null].includes(item.data.model.value)) {
          }
        }
      });

      this.$emit('applyFilter');
      this.closeFilterDialog();
    },

    clearFilters() {
      this.filterCounter = 0;
      // this.clearChips();
      this.$emit('clearFilters');
      this.closeFilterDialog();
    },

    addChip(item) {
      //console.log('add chip', item.componentOptions.tag);

      let tag = item.componentOptions.tag;
      let label = item.componentOptions.propsData.label;
      let length = item.componentOptions.propsData.value.length;
      let itemId = item.componentOptions.propsData.id;

      if (tag === 'SelectMultiple') {
        // console.log('select id', itemId);
        // console.log('select tag', tag);
        // console.log('select label', label);
        // console.log('select length', length);
      }

      let chip = this.createChipObject(itemId, label, length);
      // console.log('chip', chip);
      // console.log('filterChips list', this.filterChips);

      if (this.filterChips.length === 0) {
        this.filterChips.push(chip);
      } else {
        this.filterChips.forEach((filterChip) => {
          if (filterChip.id === chip.id) {
            filterChip.quantity = chip.quantity;
          } else if (!this.filterChips.some((filterChip) => JSON.stringify(filterChip) === JSON.stringify(chip))) {
            this.filterChips.push(chip);
          }
        });
      }
    },

    createChipObject(id, label, quantity) {
      return {
        id: id,
        label: label,
        quantity: quantity,
      };
    },

    removeChip(id) {
      this.filterChips.forEach((chip, index) => {
        if (chip.id === id) {
          this.filterChips.splice(index, 1);
        }
      });
    },

    clearChips() {
      this.filterChips = [];
    },

    closeChip(chip) {
      //console.log('close chip', chip);
    },
  },
};
</script>

<style scoped lang="scss">
@import './src/design/variables.scss';

.v-card {
  padding: 0 !important;
}

.filter-title {
  font-size: $font-size-sm;
  font-weight: $font-weight-bold;
}

.filter-dropdown {
  width: 384px;
  max-width: 384px;
  max-height: var(--height-dialog);
  height: var(--height-dialog);
  padding: $spacing-05;
  height: fit-content;

  .filter-dropdown-header {
    border-bottom: $border-width-hairline $border-default var(--v-primaryLigth-base);
    padding: $spacing-inset-05;

    .v-icon {
      cursor: pointer;
    }
  }

  .filters-dropdown-content {
    padding: $spacing-inset-05 $spacing-inset-05 0 $spacing-inset-05;
    ::v-deep div:nth-child(1) {
      padding-top: 0 !important;
    }
  }

  .filters-buttons-container {
    padding: 0 $spacing-inset-05 $spacing-inset-05 $spacing-inset-05;
    .v-btn {
      letter-spacing: 0.16px;
    }
    .btn-apply-filter {
      padding-top: $spacing-07;
      padding-bottom: $spacing-05;

      .v-btn {
        width: 100% !important;
      }
    }

    .btn-clear-filter {
      .v-btn {
        width: 100% !important;
      }
    }
  }
}
.filter-chip {
  margin: $spacing-03 0 $spacing-03 $spacing-03;
}
</style>
