var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { class: _vm.contentClass }, [
    _c("div", { staticClass: "d-flex justify-space-between" }, [
      _vm.showTitle
        ? _c("div", { staticClass: "filter-title" }, [_vm._v("Filtros")])
        : _vm._e(),
      _c("div", { staticClass: "d-flex align-center" }, [
        _c("div", { staticClass: "filter-title-spacing" }, [
          !_vm.showSwitch
            ? _c(
                "div",
                { staticClass: "d-flex" },
                _vm._l(_vm.switches, function (item) {
                  return _c("AGSwitch", {
                    key: item.id,
                    attrs: {
                      id: item.id,
                      name: item.id + "_name",
                      count: _vm.countCedido,
                      label: item.label,
                      disabled: item.disabled,
                    },
                    on: {
                      input: function ($event) {
                        return _vm.toggleSwitch(item)
                      },
                    },
                    model: {
                      value: item.value,
                      callback: function ($$v) {
                        _vm.$set(item, "value", $$v)
                      },
                      expression: "item.value",
                    },
                  })
                }),
                1
              )
            : _vm._e(),
        ]),
        _c(
          "div",
          { staticClass: "filter-spacing", attrs: { id: _vm.idToAttach } },
          [
            _c(
              "v-menu",
              {
                ref: "filter_menu",
                attrs: {
                  "close-on-content-click": false,
                  "close-on-click": true,
                  persistent: true,
                  "z-index": "4",
                  "offset-y": "",
                  left: "",
                  bottom: "",
                  attach: "#" + _vm.idToAttach,
                },
                scopedSlots: _vm._u([
                  {
                    key: "activator",
                    fn: function ({ on, attrs }) {
                      return [
                        _c(
                          "v-btn",
                          _vm._g(
                            _vm._b(
                              {
                                staticClass: "btn-ag-secondary",
                                attrs: {
                                  id: "btnFilter",
                                  disabled: _vm.disabled,
                                },
                                on: { click: _vm.onClickFilter },
                              },
                              "v-btn",
                              attrs,
                              false
                            ),
                            on
                          ),
                          [
                            _c(
                              "v-icon",
                              { staticClass: "material-icons-outlined" },
                              [_vm._v(" filter_alt ")]
                            ),
                            _vm._v(
                              " Filtro " +
                                _vm._s(_vm.filterSelectedCounter) +
                                " "
                            ),
                          ],
                          1
                        ),
                      ]
                    },
                  },
                ]),
                model: {
                  value: _vm.menuActive,
                  callback: function ($$v) {
                    _vm.menuActive = $$v
                  },
                  expression: "menuActive",
                },
              },
              [
                _c("v-card", { staticClass: "filter-dropdown" }, [
                  _c(
                    "div",
                    {
                      staticClass:
                        "d-flex flex-row justify-space-between filter-dropdown-header",
                    },
                    [
                      _c("strong", { staticClass: "filter-title" }, [
                        _vm._v(" Filtro "),
                      ]),
                      _c(
                        "div",
                        [
                          _c(
                            "v-icon",
                            {
                              staticClass: "primary--text",
                              on: { click: _vm.closeFilterDialog },
                            },
                            [_vm._v(" close ")]
                          ),
                        ],
                        1
                      ),
                    ]
                  ),
                  _c(
                    "div",
                    { staticClass: "filters-dropdown-content" },
                    [_vm._t("filters")],
                    2
                  ),
                  _c("div", { staticClass: "filters-buttons-container" }, [
                    _c(
                      "div",
                      { staticClass: "btn-apply-filter" },
                      [
                        _c(
                          "v-btn",
                          {
                            staticClass: "btn-ag-primary",
                            attrs: { disabled: _vm.disabledFilterApplyBtn },
                            on: {
                              click: function ($event) {
                                return _vm.applyFilter()
                              },
                            },
                          },
                          [_vm._v(" Aplicar ")]
                        ),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "btn-clear-filter" },
                      [
                        _c(
                          "v-btn",
                          {
                            staticClass: "btn-ag-secondary",
                            attrs: { disabled: !_vm.hasFilterSelected },
                            on: {
                              click: function ($event) {
                                return _vm.clearFilters()
                              },
                            },
                          },
                          [
                            _c("v-icon", [_vm._v(" mdi-delete-outline ")]),
                            _vm._v(" Limpar filtro "),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ]),
                ]),
              ],
              1
            ),
          ],
          1
        ),
      ]),
    ]),
    _c(
      "div",
      { staticClass: "d-flex flex-row-reverse" },
      _vm._l(_vm.filterChips, function (chip, index) {
        return _c(
          "v-chip",
          {
            key: index,
            staticClass: "filter-chip",
            attrs: { close: "", "close-icon": "close" },
            on: {
              "click:close": function ($event) {
                return _vm.closeChip(chip)
              },
            },
          },
          [
            chip.quantity > 1
              ? _c("span", { staticClass: "mr-1" }, [
                  _vm._v(" (" + _vm._s(chip.quantity) + ") "),
                ])
              : _vm._e(),
            _vm._v(_vm._s(chip.label) + " "),
          ]
        )
      }),
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }